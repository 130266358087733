// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    sm: (
      fs: 12px,
      lh: 14px,
    ),
    md: (
      fs: 18px,
      lh: 24px,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: Helvetica,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: false,
  ),
        didibold: (
                name: sfcompactrounded-black,
                fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                withFile: true,
        ),
        didimedium: (
                name: sfcompactrounded-medium,
                fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                withFile: true,
        ),
        tienhang: (
                name: anton-regular,
                fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
                withFile: true,
        ),


);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
