body{
  display: grid;
  grid-template-rows: repeat(2,1fr);
  height: 100%;
  @include mq(md){
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
  }
}
a{
  color: white;
}
.section{
  height: 100%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: #B7B7B7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover{
    background-color: lightgrey;
  }
}

.logo{
  width: 23vw;
}

.section#didi{
  color: white;
  h1{
    @include font(didibold);
    font-size: 5.2vw;
    line-height: 1em;
    margin: 0;
    margin-top: 0.4em;
    @include mq(md){
      font-size: 1.95vw;
      line-height: 1em;
      margin: 0;
      margin-top: 0.25em;
    }
  }
  h2{
    @include font(didimedium);
    line-height: 1em;
    font-size: 4.3vw;
    margin: 0;
    @include mq(md){
      line-height: 1em;
      font-size: 1.6vw;
      margin: 0;
    }
  }
  .logo{
    width: 60vw;
    @include mq(md){
      width: 23vw;
    }
  }

}

.section#tienhang{
  color: white;
  h2{
    @include font(tienhang);
    letter-spacing: 0.025em;
    line-height: 1.1em;
    font-size: 4.3vw;
    margin: 0;
    @include mq(md){
      line-height: 1.1em;
      font-size: 1.6vw;
      margin: 0;
    }
  }
  .logo{
    width: 60vw;
    @include mq(md){
      width: 23vw;
    }
  }

}



